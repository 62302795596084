@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-blue: #00688d;
  --secondary-blue: #99c7d6;
}

a {
  text-decoration: none;
  color: #000;
}

a[disabled] {
  cursor: not-allowed;
  background-color: var(--secondary-blue) !important;
}

main {
  min-width: 100vw;
  min-height: 100vh;
  height: fit-content;
  font-family: "Open Sans", sans-serif;
  padding: 50px 25px;
  margin-top: 70px;
}


address {
  text-align: left;
  font-style: normal;
}



sup {
  font-size: 7.5px;
  margin-right: 2.5px;
}


h1 sup {
  font-size: 20px;
}
/*
 ================= NAV ----------------------------------------------------------
*/

.header {
  border-bottom: 1px solid #000;
  height: 75px;
  width: 100vw;
  min-height: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
  position: relative;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

.social-links-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 70px;
  left: 0;
  height: 50px;
  width: 350px;
}
.social-links-container__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  grid-row: 9/10;
  grid-column: 9/13;
}

@media screen and (max-width: 550px) {
  .social-links-container {
    width: 100%;
  }
  .social-links-container__footer {
    grid-column: 1/13;
  }
}

.social-links-container a:hover,
.social-links-container__footer a:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.facebook-link {
  background-image: url("../public/imgs/facebook.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.instagram-link {
  background-image: url("../public/imgs/instagram.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.twitter-link {
  background-image: url("../public/imgs/twitter.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.linkedin-link {
  background-image: url("../public/imgs/linkedin.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.youtube-link {
  background-image: url("../public/imgs/youtube.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.footer .facebook-link {
  background-image: url("../public/imgs/facebook-white.svg");
}
.footer .instagram-link {
  background-image: url("../public/imgs/instagram-white.svg");
}
.footer .twitter-link {
  background-image: url("../public/imgs/twitter-white.svg");
}
.footer .linkedin-link {
  background-image: url("../public/imgs/linkedin-white.svg");
}
.footer .youtube-link {
  background-image: url("../public/imgs/youtube-white.svg");
}

.header-logo-cont {
  overflow: hidden;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header--logo {
  width: 250px;
  height: auto;
  cursor: pointer;
}

.nav {
  width: 600px;
  font-size: 1rem;
}

.nav--links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  height: 75px;
}

.nav--links > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  position: relative;
  font-weight: 500;
}

.nav--links > li::after {
  position: absolute;
  content: url(/public/imgs/down-arrow.svg);
  width: 10px;
  height: 10px;
  right: 10px;
  bottom: 44%;
  transition: all ease-in 250ms;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}
.nav--links li:hover::after {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.nav--links > li:first-of-type:after {
  position: absolute;
  content: url(/public/imgs/down-arrow.svg);
  width: 10px;
  height: 10px;
  right: 5px;
  bottom: 44%;
  transition: all ease-in 250ms;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.nav--links > li:first-of-type a {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav--links li:first-of-type:hover::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: 45%;
}


.nav--links li ul {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  list-style-type: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  justify-content: center;
  flex-direction: column;
  text-align: right;
}

.nav--links li:hover ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background-color: #fff;
  align-items:flex-end;
  justify-content: center;
}

.nav--links li ul li {
  width: 100%;
  height: 50px;
  font-weight: 300;
  background-color: #fff;
  text-align: right;
}

.nav--links li ul > li a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 10px;
}

.nav--links li ul li:hover {
  background-color: var(--secondary-blue);
}

.nav--links__resources-links {
  width: 100%;
}

.nav--links__products-links {
  width: 335px;
}
.nav--links__downloads-links {
  width: 335px;
}
.nav--links__education-links {
  width: 225px;
}

.hamburger {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.hamburger div {
  position: absolute;
  background-color: #000;
  height: 2.5px;
  width: 100%;
  border-radius: 20px;
  display: none;
  transition: all ease 500ms;
}

.hamburger div::after {
  position: absolute;
  background-color: #000;
  content: "";
  height: 2.5px;
  width: 100%;
  border-radius: 20px;
  bottom: -10px;
  left: 0;
  transition: all ease 500ms;
}
.hamburger div::before {
  position: absolute;
  background-color: #000;
  content: "";
  height: 2.5px;
  width: 100%;
  border-radius: 20px;
  top: -10px;
  left: 0;
  transition: all ease 500ms;
}

.hamburger.active-nav div::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  bottom: 0px;
}

.hamburger.active-nav div::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0px;
}

.hamburger.active-nav div {
  background-color: #fff;
  z-index: 99;
}

.mobile-nav-links {
  display: none;
}

@media screen and (max-width: 820px) {
  .header {
    padding: 0px 25px;
  }

  .header--logo {
    width: 150px;
  }

  .hamburger div {
    display: block;
  }

  .nav {
    width: 100vw;
    min-width: fit-content;
    height: 100vh;
    font-size: 1rem;
    position: absolute;
    right: -150%;
    top: calc(100% + 0px);
    background-color: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    border: 1px solid #000;
    transition: all ease-in-out 500ms;
  }

  .nav.active-nav {
    right: 0;
    height: 100vh;
    width: 100%;
  }

  .nav--links {
    flex-direction: column;
    height: fit-content;
  }

  .nav--links > li {
    height: 100%;
    width: 100%;
    position: relative;
    font-weight: 600;
    margin: 0 100px;
    padding: 25px 0;
    flex-direction: column;
  }

  .nav--links > li::after {
    position: absolute;
    content: url(/public/imgs/down-arrow.svg);
    width: 10px;
    height: 10px;
    right: 75px;
    top: 28px;
  }
  .nav--links li:hover::after {
    -webkit-transform: translateY(0%) rotate(90deg);
    transform: translateY(0%) rotate(90deg);
  }


.nav--links > li:first-of-type:after {
  position: absolute;
  content: '';

}
.nav--links > li:first-of-type {
 text-decoration: underline;
}

  .nav--links li ul {
    position: relative;
    top: 100%;
    right: 0;
    display: none;
    list-style-type: none;
    width: 100%;
    box-shadow: none;
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .nav--links li:hover ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .nav--links li ul li {
    background-color: #fff;
    padding-right: 20px;
    text-align: left !important;
  }
  .nav--links li ul li a {
    text-align: right;
  }

  .mobile-nav-links {
    position: absolute;
    width: 100%;
    bottom: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%);
    display: block;
    text-align: center;
  }

}

/*
 ================= HOME ----------------------------------------------------------
*/

.home-container {
  width: 100%;
  height: fit-content;
}

.home-carousel-container {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  position: relative;
}

.home-carousel-content {
  width: 500%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  transition: transform ease 500ms;
  overflow: hidden;
}
.end-carousel-swtich {
  width: 500%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  transition: none;
  overflow: hidden;
}

 .home-carousel-content div img {
  width: 100%;

  height: 100%;
  display: block;
} 

.hero-carousel-slide {
  width: 20%;
  max-width: 25%;
  height: fit-content;
  display: block;
  overflow: hidden;
  position: relative;
}

.hero-carousel-slide div {
  width: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(0, 104, 141, 0.5);
  padding: 50px 50px;
}

.home-carousel-content .hero-carousel-slide:first-of-type div {
  left: 30%;
}
.home-carousel-content .hero-carousel-slide:nth-of-type(2) div, 
.home-carousel-content .hero-carousel-slide:nth-of-type(4) div {
  left: 70%;
}

.hero-carousel-slide h2 {
  color: #fff;
  font-size: 4rem;
  text-shadow: -0.15rem 0.15rem 1px var(--secondary-blue);
  text-align: center;
}
.hero-carousel-slide p {
  color: #fff;
  font-size: 2rem;
  text-shadow: -.1rem .05rem 1px var(--secondary-blue)
}


@media screen and (max-width: 1500px) {
  .hero-carousel-slide h2 {
    font-size: 2rem;
  }
  .hero-carousel-slide p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 900px) {
  .hero-carousel-slide div {
    padding: 25px 25px;
    width: 300px;

  }
  .hero-carousel-slide h2 {
    font-size: 2rem;
  }
  .hero-carousel-slide p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .home-carousel-content .hero-carousel-slide:first-of-type div {
    left: 40%;
  }

  .home-carousel-content .hero-carousel-slide:nth-of-type(4) div, 
  .home-carousel-content .hero-carousel-slide:nth-of-type(5) div {
    left: 65%;
  }
  .hero-carousel-slide div {
    padding: 20px 20px;
  
  }
  .hero-carousel-slide h2 {
    color: #fff;
    font-size: 1rem;
  }
  .hero-carousel-slide p {
    font-size: 12px;

  }
}

@media screen and (max-width: 400px) {
  .hero-carousel-slide div {
    padding: 10px 10px;
  
  }
  .hero-carousel-slide h2 {
    color: #fff;
    font-size: 16px;
  }
  .hero-carousel-slide p {
    font-size: 8px;

  }
}




.carousel-controls {
  position: absolute;
  width: 50%;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.carousel-controls button {
  padding: 7.5px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 104, 141,0.5);
}
.carousel-controls button:active {
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

button.active-selector {
  border: 1px solid #fff;
  box-shadow: 0px 0px 2px #000;
}

.home h1 {
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 2.5rem;
  text-align: center;
  margin: 50px auto;
}

.products-home {
  width: 100%;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.products-home div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.products-home div div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 25px;
}

.products-home div div img {
  width: auto;
  height: 100px;
  margin-bottom: 25px;
  transition: transform ease 250ms;
}

.products-home .unit {
  padding-top: 25px;
}

.products-home div div h3 {
  font-size: 16px;
  transition: transform ease 250ms;
}


.products-home div a > div:hover img,
.products-home div a > div:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.products-home div > div,
.products-home div > div {
  cursor: pointer;
  margin: 25px;
}
.products-home div a > div:hover h3,
.products-home div a > div:hover h3 {
  color: var(--main-blue);
}

@media screen and (max-width: 850px) {
  .home-container {
    width: calc(100% + 50px);
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }

  .carousel-controls {
    position: absolute;
    width: 75%;
    height: 50px;
  }

  .products-home {
    flex-direction: column;
    width: 100%;
    height: fit-content;
  }

  .products-home div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    margin-top: 25px;
  }
  .products-home div div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 0px 50px;
  }
}
@media screen and (max-width: 500px) {
  .home-container {
    height: 300px;
  }

  .home-carousel-container {
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .home-carousel-content {
    height: 100%;
  }

  .home-carousel-content img {
    width: 20%;
    height: 100%;
    display: block;
  }

  .carousel-controls {
    position: absolute;
    width: 75%;
    height: 50px;
  }
  .products-home {
    flex-direction: column;
    width: 100%;
    height: fit-content;
  }

  .products-home div {
    flex-direction: column;
    margin-top: 25px;
  }
  .products-home div h3 {
    flex-direction: column;
    margin-bottom: 25px;
  }
}

/*
 ================= ABOUT US ----------------------------------------------------------
*/

.about-us {
}

.about-us > img {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  margin-bottom: 25px;
}

.about-us article {
  width: 95%;
  margin: 0 auto;
}

.about-us > p:last-of-type {
  text-align: center;
}

.about-us h1 {
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 2.5rem;
  text-align: center;
}

.team-member-container {
  display: flex;
  width: 60%;
  margin: 25px auto;
  background-color: #d4d4d4;
  height: 250px;
  overflow: hidden;
  font-size: 0.75rem;
}

.team-member-container:hover {
  background-color: #aaaaaa;
}
.team-member-container img {
  max-width: 200px;
  height: 100%;
  transition: all ease 250ms;
  object-fit: cover;
  object-position: center;
}
.team-member-container:hover img {
  -webkit-transform: scale(1.025);
  transform: scale(1.025);
}

.team-member-container article {
  padding: 10px;
}
.team-member-container article h3 {
  margin-bottom: 5px;
}

.about-us__organization-container {
  background-color: #aaaaaa;
  display: flex;
  height: 150px;
  width: 90%;
  margin: 0 auto;
}

.about-us__organization {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 20%;
  font-weight: 600;
  padding: 10px;
  font-size: 0.75rem;
}
.about-us__organization:nth-of-type(even) {
  background-color: var(--secondary-blue);
}
.about-us__organization:nth-of-type(odd) {
  background-color: #aaaaaa;
}


@media screen and (min-width: 1700px) {
  .about-us {
    font-size: 1.5rem;
  }
  .team-member-container {
    font-size: 1.25rem;
  }
  .team-member-container {
    height: 350px;
  }
}

@media screen and (max-width: 1300px) {
  .team-member-container {
    font-size: 0.75rem;
    width: 70%;
  }

  .about-us__organization {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 900px) {
  .team-member-container {
    font-size: 0.55rem;
    width: calc(100% + 50px);
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
  .team-member-container img {
    width: 150px;
    height: 100%;
  }
  .about-us__organization-container {
    width: calc(100% + 50px);
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }

  .about-us__organization {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 650px) {
  .about-us h1 {
    font-size: 2rem;
  }
  .about-us > article > p {
    font-size: 0.9rem;
  }
  .team-member-container {
    width: calc(100% + 50px);
    height: fit-content;
  }
  .about-us__organization-container {
    width: calc(100% + 50px);
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }

  .about-us__organization {
    font-size: 0.55rem;
  }
}
@media screen and (max-width: 500px) {

  .about-us h1 {
    font-size: 1.5rem;
  }
  .about-us > article > p {
    font-size: 0.8rem;
  }

  .team-member-container {
    width: calc(100%);
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    flex-direction: column;
    text-align: center;
    max-height: fit-content;
  }
  .team-member-container img {
    width: 100px;
    height: 100%;
    transition: all ease 250ms;
    margin: 0 auto;
  }
}

/*
 ================= WHERE WE ARE ----------------------------------------------------------
*/

.where-we-are {
}

.where-we-are img {
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 25px;
}

.where-we-are h1 {
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 2.5rem;
  text-align: center;
}

.where-we-are h3 {
  text-align: center;
}

.where-we-are a {
  color: var(--main-blue);
}
.where-we-are a:hover {
  color: var(--secondary-blue);
}

.country-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.country-container p {
  margin: 25px 50px;
  position: relative;
}

.country-container p:nth-of-type(1)::after {
  position: absolute;
  content: url("/public/imgs/flags/canada.svg");
  right: -30px;
  top: 0px;
}
.country-container p:nth-of-type(2)::after {
  position: absolute;
  content: url("/public/imgs/flags/chile.svg");
  right: -30px;
  top: 0px;
}

.country-container p:nth-of-type(3)::after {
  position: absolute;
  content: url("/public/imgs/flags/jordan.svg");
  right: -30px;
  top: 0px;
}
.country-container p:nth-of-type(4)::after {
  position: absolute;
  content: url("/public/imgs/flags/mexico.svg");
  right: -30px;
  top: 0px;
}

.country-container p:nth-of-type(5)::after {
  position: absolute;
  content: url("/public/imgs/flags/united-states.svg");
  right: -30px;
  top: 0px;
}
.country-container p:nth-of-type(6)::after {
  position: absolute;
  content: url("/public/imgs/flags/saudi-arabia.svg");
  right: -30px;
  top: 0px;
}
.country-container p:nth-of-type(7)::after {
  position: absolute;
  content: url("/public/imgs/flags/singapore.svg");
  right: -30px;
  top: 0px;
}

@media screen and (max-width: 500px) {
  .where-we-are h1 {
    font-size: 2rem;
  }
  .where-we-are img {
    width: calc(100% + 50px);
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
}

/*
 ================= CONTACT US ----------------------------------------------------------
*/

.contact-us {
  text-align: center;
}

.contact-us img {
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 25px;
}

.contact-us h1 {
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 2.5rem;
  text-align: center;
}
.contact-us p {
  width: 75%;
  text-align: center;
  margin: 0 auto;
}

.form-container {
  width: 50%;
  margin: 0 auto;
}

.form {
  display: flex;
  flex-direction: column;
}

.form input,
.form select,
.form textarea {
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 16px;
  padding: 7.5px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

textarea {
  resize: none;
}

.form label {
  text-align: left;
  padding-left: 10px;
  font-weight: 500;
}

#submit-btn {
  background-color: var(--secondary-blue);
  width: 250px;
  margin: 0px auto;
  cursor: pointer;
  color: #fff;
}

#submit-btn:hover {
  background-color: var(--main-blue);
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}
#submit-btn:active {
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

@media screen and (max-width: 1000px) {
  .form-container {
    width: 75%;
  }
}

@media screen and (max-width: 500px) {
  .contact-us img {
    width: calc(100% + 50px);
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
  .form-container {
    width: 100%;
  }
  .contact-us h1 {
    width: 100%;
  }
  .contact-us p {
    width: 100%;
  }
}

#products-interested-in {
  text-align: left;
  margin: 10px;
  padding-left: 20px;
}
#products-interested-in input {
margin: 0;
}
#products-interested-in label {
cursor: pointer;
}
#products-interested-in div {
width: 100%;
margin: 15px 5px;
}

/*
 ================= PRODUCTS MAIN ----------------------------------------------------------
*/

.products-AA,
.products-FMV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-height: 500px;
  min-height: 500px;
  text-align: center;
  flex-direction: column;
  margin-top: 70px;
}
.products-AA div,
.products-FMV div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 50px;
}
.products-AA div div,
.products-FMV div div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.products-AA h1,
.products-FMV h1 {
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 5rem;
}

.products-AA img,
.products-FMV img {
  width: auto;
  max-height: 200px;
  margin-bottom: 25px;
  transition: transform ease 250ms;
}
.products-AA .unit,
.products-FMV .unit {
  width: auto;
  padding-top: 75px;
  transition: transform ease 250ms;
}


.products-AA div a > div:hover img,
.products-FMV div a > div:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.products-AA > div,
.products-FMV > div {
  cursor: pointer;
  margin: 25px;
}
.products-AA div a > div:hover h3,
.products-FMV div a > div:hover h3 {
  color: var(--main-blue);
}

@media screen and (min-width: 1800px) {
  .products-AA h1,
  .products-FMV h1 {
    font-size: 7rem;
  }

  .products-AA img,
  .products-FMV img {
    width: auto;
    height: 500px;
    margin-bottom: 25px;
  }

  .products-AA h3,
  .products-FMV h3 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 800px) {
  .products-AA h1,
  .products-FMV h1 {
    font-size: 3.5rem;
    margin: 50px 0;
  }

  .products-AA img,
  .products-FMV img {
    width: auto;
    height: 250px;
  }

  .products-AA .unit,
  .products-FMV .unit {
  width: auto;
  padding-top: 120px;
  transition: transform ease 250ms;
}

  .products-AA,
  .products-FMV {
    height: fit-content;
    max-height: fit-content;
    min-height: fit-content;
    flex-direction: column;
    margin-top: 0px;
  }

  .products-AA h3,
  .products-FMV h3 {
    font-size: 1rem;
    margin-bottom: 50px;
  }
  .products-AA div,
  .products-FMV div {
    flex-direction: column;
  }
}
/*
 ================= PRODUCTS PAGE INDIVIDUAL ----------------------------------------------------------
*/

.AA-5-ml-cont,
.AA-ampules-cont,
.FMV-ampule-cont,
.FMV-bottle-cont {
  float: right;
  width: 80%;
  margin-bottom: 25px;
  padding-left: 15px;
}

.AA-5-ml-cont::after,
.AA-ampules-cont::after,
.FMV-ampule-cont::after,
.FMV-bottle-cont::after {
  display: table;
  clear: both;
  content: "";
}

.product-aside {
  width: 20%;
  height: 100vh;
  float: left;
  margin-top: 75px;
}
.product-aside h2 {
  color: var(--main-blue);
  font-weight: 100;
  text-align: center;
  width: 100%;
}

.product-aside div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  position: sticky;
  top: 100px;
  height: 350px;
}

.AA-5-ml-cont a,
.AA-ampules-cont a,
.FMV-ampule-cont a,
.FMV-bottle-cont a,
.product-aside a {
  color: var(--main-blue);
  text-decoration: underline;
}
.AA-5-ml-cont a:hover,
.AA-ampules-cont a:hover,
.FMV-ampule-cont a:hover,
.FMV-bottle-cont a:hover,
.product-aside a:hover {
  color: var(--secondary-blue);
  text-decoration: underline;
}

.AA-5-ml-cont img,
.AA-ampules-cont img {
  width: 200px;
  height: auto;
  margin: 25px auto;
  display: block;
}

.FMV-ampule-cont img,
.FMV-bottle-cont img {
  width: auto;
  height: 350px;
  margin: 25px auto;
  display: block;
}

.AA-5-ml-cont h1,
.AA-ampules-cont h1,
.FMV-ampule-cont h1,
.FMV-bottle-cont h1 {
  text-align: center;
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 2.5rem;
}

.AA-5-ml-cont h3,
.AA-ampules-cont h3,
.FMV-ampule-cont h3,
.FMV-bottle-cont h3 {
  text-align: center;
}

.AA-5-ml-cont ul,
.AA-ampules-cont ul,
.FMV-ampule-cont ul,
.FMV-bottle-cont ul {
  padding-left: 50px;
}

@media screen and (max-width: 800px) {
  .AA-5-ml-cont,
  .AA-ampules-cont,
  .FMV-ampule-cont,
  .FMV-bottle-cont {
    float: none;
    width: 100%;
    margin-bottom: 0px;
    padding-left: 0px;
  }
  .product-aside {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .AA-5-ml-cont h1,
  .AA-ampules-cont h1,
  .FMV-ampule-cont h1,
  .FMV-bottle-cont h1 {
    font-size: 1.5rem;
  }

  .AA-5-ml-cont img,
  .AA-ampules-cont img {
    width: 150px;
    height: auto;
    margin: 25px auto;
    display: block;
  }

  .FMV-ampule-cont img,
  .FMV-bottle-cont img {
    width: auto;
    height: 250px;
    margin: 25px auto;
    display: block;
  }
}

/*
 ================= DOWNLOADS ----------------------------------------------------------
*/

.downloads {
}

.downloads h1 {
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 2.5rem;
  text-align: center;
}

.downloads-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
}
.downloads-container div {
  width: 100%;
}
.downloads-container div > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.downloads-container div div a {
  color: #fff;
  background-color: var(--main-blue);
  padding: 15px 50px;
}
.downloads-container div div a:hover {
  color: #fff;
  background-color: var(--secondary-blue);
}

.downloads-container h2 {
  margin: 25px 0px;
  text-align: center;
  width: 100%;
}

.downloads-container h3 {
  margin: 50px 0px 25px 0px;
}

.downloads-container p {
  margin: 25px 0;
}

@media screen and (max-width: 1000px) {
  .downloads-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 550px) {
  .downloads h1 {
    font-size: 2rem;
  }

  .downloads-container div > div {
    flex-direction: column;
    width: 100%;
  }

  .downloads-container h2 {
    margin: 50px 0px 25px 0px;
    text-align: center;
    width: 100%;
  }
}

/*
 ================= CE LIBRARY ----------------------------------------------------------
*/

.ce-library {
  overflow-x: hidden;
}
/*
.ce-library img {
  width: 500px;
}

.ce-library h1 {
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 2.5rem;
  text-align: center;
  margin: 50px auto;
}

.ce-library hr {
  width: 50%;
  margin: 15px;
  margin: 0 auto;
}
.ce-library hr:last-of-type {
  margin-bottom: 75px;
}
.ce-library p {
  margin: 50px auto;
}


.ce-library a:hover {
  color: #fff;
  background-color: var(--secondary-blue);
}

@media screen and (max-width: 550px) {
  .ce-library img {
    width: 100%;
  }
  
} */

.webinarcell {
  width: 31.9%;
  float: left;
  margin-right: 10px;
  min-height: 340px;
  background: #efefef;
  border-bottom: solid 3px #71c6ce;
  padding: 10px;
  margin-bottom: 20px;
  }

  .webinartitle {
    font-size: 13px;
    color: #000;
  }

  .webinardeck {
    font-size: 11px;
    color: #999;
    margin-top: 5px;

  }

  .ce-library a {
    color: var(--main-blue);
    cursor: pointer !important;
  }
  .ce-library h3 {
    cursor: pointer !important;
  }
  .ce-library hr  {
      width: 75%;
      margin: 25px auto;
  }

  @media screen and (max-width: 1160px)  {

    .webinarcell {
      min-height: 440px !important;
    }

  }

  @media screen and (max-width: 767px)  {

    .webinarcell {
      width: 100% !important;
      float: none !important;
      min-height: auto !important;
    }

    .stickyBody iframe {
      height: auto !important;
    }

  }

  @media screen and (max-width: 480px)  {

    .webinarcell {
      width: 98% !important;
      float: none !important;
    }

  }

/*
 ================= STUDIES ----------------------------------------------------------
*/

.studies {
text-align: center;
}

.studies h1 {
    width: 100%;
    color: var(--main-blue);
    font-weight: 100;
    font-size: 2.5rem;
    text-align: center;
    margin: 50px auto;
}

.studies div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin: 100px auto;
}
.studies h2 {
  margin: 50px auto;
}

.studies a {
  color: #fff;
  background-color: var(--main-blue);
  padding: 15px 50px;
}

.studies a:hover {
  color: #fff;
  background-color: var(--secondary-blue);
}

@media screen and (max-width: 850px) {
  .studies div {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .studies div {
    width: 100%;
    flex-direction: column;
  }
  .studies div p {
   margin-bottom: 25px;
  }
}
/*
 ================= FLUORIMAX DATA ----------------------------------------------------
*/

.fluoriMax-data {
margin-top: 70px;
}
.fluoriMax-data h1 {
    width: 100%;
    color: var(--main-blue);
    font-weight: 100;
    font-size: 2.5rem;
}
.fluoriMax-data h1:last-of-type {
  margin-bottom: 50px;
}
.fluoriMax-data img {
  margin: 50px;
}

.fluoriMax-data a {
  width: 100%;
  color: var(--main-blue);
  font-weight: 100;
  font-size: 2.5rem;
  margin: 50px;

  text-decoration: underline;
}

@media screen and (max-width: 550px) {
  .fluoriMax-data h1 {
    font-size: 1.5rem;
}
  .fluoriMax-data img {
    margin: 0px;
    width: 250px;
  }
  .fluoriMax-data a  {
    margin: 0px;
  }
}


/*
 ================= PRIVACY POLICY ----------------------------------------------------
*/

.privacy {
  font-size: 0.85rem;
}

/*
 ================= TERMS OF USE ----------------------------------------------------------
*/

.terms-of-use {
  font-size: 0.85rem;
}

/*
 ================= FOOTER ----------------------------------------------------------
*/

.footer {
  background-color: var(--main-blue);
  width: 100%;
  height: 300px;
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: repeat(12, 1fr);
  color: #fff;
  font-size: 1rem;
  text-align: center;
}

.footer a {
  color: #fff;
}
.footer h3 {
  text-decoration: underline;
}
.footer a:hover {
  color: var(--secondary-blue);
}

.footer div ul {
  padding: 0 5px;
}

.footer div ul {
  color: #fff;
  list-style-type: none;
}
.footer div ul li {
  color: #fff;
  list-style-type: none;
  padding: 5px 0px;
}

.footer__logo-col {
  white-space: nowrap;
}

.footer__logo-col img {
  max-width: 250px;
}

.footer__logo-col {
  height: auto;
  grid-row: 1/8;
  grid-column: 1/3;
  margin-left: 50px;
}
.footer__resources-col {
  grid-row: 2/9;
  grid-column: 4/7;
  justify-self: center;
}
.footer__products-col {
  grid-row: 2/9;
  grid-column: 6/8;
  justify-self: center;
}
.footer__downloads-col {
  grid-row: 2/8;
  grid-column: 8/10;
  justify-self: center;
}
.footer__education-col {
  grid-row: 2/9;
  grid-column: 10/13;
  justify-self: center;
  margin-right: 25px;
}

.social-links-container__footer {
  margin-bottom: 15px;
}

.footer__lower-copyright-container {
  background-color: #fff;
  grid-row: 10/12;
  grid-column: 1/13;
  width: 100%;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__lower-copyright-container p,
.footer__lower-copyright-container a {
  font-family: "Open Sans", "sans serif";
  font-weight: 600;
  margin: 0 25px;
  color: var(--main-blue);
}

@media screen and (max-width: 1050px) {
  .footer {
    font-size: 0.75rem;
  }
  .footer__logo-col img {
    width: 200px;
  }

  .footer__lower-copyright-container p,
  .footer__lower-copyright-container a {
    font-size: 10px;
    margin: 0 15px;
  }
}

@media screen and (max-width: 650px) {
  .footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: fit-content;
    font-size: 1rem;
  }

  .footer div {
    text-align: center;
    margin: 10px auto;
    width: 100%;
  }

  address {
    text-align: center;
  }

  .footer__logo-col img {
    width: 250px;
  }



  .social-links-container__footer a {
    width: 25px;
  }

  .footer__lower-copyright-container p,
  .footer__lower-copyright-container a {
    font-size: 10px;
    margin: 0 5px;
  }
  .footer__lower-copyright-container {
    flex-wrap: wrap;
  }
  .footer__lower-copyright-container {
    margin-bottom: 0 !important;
  }
}
/*
 ================= ERROR PAGE ----------------------------------------------------------
*/

.error-page-container {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
}
.error-page-svg {
  margin-bottom: 25px;
  background-image: url("/public/imgs/error-page.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

/*
 ================= MAIL SENT PAGE ----------------------------------------------------------
*/
 .mail-sent-container {
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   color: var(--main-blue);
   min-height: 100%;
   margin-top: 50px;
   text-align: center;
 }

 .mail-sent-container img {
   height: 250px;
 }

 .mail-sent-container h1 {
   margin: 50px 0px;
 }
 .mail-sent-container a {
   font-size: 24px;
   text-decoration: underline;
   color: var(--main-blue);
   text-decoration-color: var(--secondary-blue);
 }


 #email-spam-honeypot {
   display: none;
 }